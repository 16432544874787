import React, { useEffect, useState } from 'react';
import { Box, IconButton, Add as AddIcons } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, Layers as CategoryIcon, Visibility as ViewIcon, ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';
import NavBar from '../../navBar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from "../common/button_one";
import { basic } from '../../../themes/basic';
import PageHeading from "../page_heading";

export default function MangeCustomer() {
    const __navigate = useNavigate();
    const [__customersData, __setCustomersData] = useState([]);
    const [__selecteCustomer, __setSelectedCustomer] = useState([]);

    const __selectedCustomerFunc = (selectedItems) => {
        __setSelectedCustomer(selectedItems)
    };

    const __moveToAddCustomerPage = () => {
        __navigate("/adminDashboard/customer/add");
    }

    const __getAllCustomers = async () => {
        const _getCustomerDataReponse = await axios.get("/customers/");   
        const _customerData = _getCustomerDataReponse?.data?.map((aItem) => ({
            id:aItem._id,
            ...aItem,
            customerName:aItem.customerName,
            email: aItem.cpEmail,
            type: aItem.type,
            lmCode: aItem.lmCode,
            contactNumber:aItem.cpMobile,    
            udiseCode:aItem?.udiseCode       
          }));
        console.log(_customerData);
        __setCustomersData(_customerData || []);
      }


    useEffect(async () => {
        __getAllCustomers();
    }, []);

    return (
        <div style={{ marginTop: "0px", position:"relative" }}>

            <PageHeading title="Manage Customer" style={{marginBottom:"20px"}} /> 

            <div style={{ display: "flex", position:"absolute", marginTop: 12,zIndex:"1000" }}>
                <CustomButton
                onClick={__moveToAddCustomerPage}
                aria-label="Add Customer"
                >
                    <AddIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-2}}/>
                    <div style={{ marginLeft: 5 }}>Add Customer</div>
                </CustomButton>            
            </div>
        
            <Box
                sx={{
                height: "auto",
                width: "100%",
                ul: {
                    bgcolor: "#f6f6f6",
                    color: "#ffffff",
                },
                }}
            >
                <DataGridStyle
                rows={__customersData}
                columns={[                       
                    {
                        field: "lmCode",
                        flex: 1.5,
                        headerName: "LM Code",
                        headerAlign: "left",
                    },    
                    {
                        field: "customerName",
                        headerName: "Customer Name",
                        flex: 2.5,
                        headerAlign: "left",
                    },  
                    {
                        field: "udiseCode",
                        headerName: "UDISE Code",
                        hide: true,
                    }, 
                    {
                        field: "email",
                        headerName: "Contact Person Email",
                        flex: 2.5,
                        headerAlign: "left",
                    }, 
                    {
                        field: "type",
                        headerName: "Type",
                        flex: 1,
                        maxWidth: 100,
                        headerAlign: "left",
                    },                 
                    {
                        field: "contactNumber",
                        headerName: "Mobile Number",
                        flex: 1,
                        headerAlign: "left",
                    },
                    /*{
                        field: "status",
                        headerName: "Status",
                        flex: 0.8,
                        headerAlign: "left",
                        renderCell: (params) => (
                            <Box>
                                <span style={{ color:params.row.status === "active" ? "green": "black", textTransform: "capitalize" }}>{params.row.status}</span>
                            </Box>
                        )
                    }, */
                    {
                    field: "actions",
                    headerName: "Actions",
                    flex: 0.9,
                    minWidth: 100,                    
                    headerAlign: "left",
                    disableExport: true,
                    sortable: false,
                    renderCell: (params) => (
                        <Box>
                            <IconButton 
                                disabled={params.row.status === false}                                             
                                onClick={() => { 
                                    if(params.row.status === true){
                                        localStorage.setItem("_accountId", params.row.id);                            
                                        __navigate("/schoolDashboard/users");
                                    }
                                }}
                            >
                                <ManageAccountsIcon  titleAccess="Manage Account" style={{fontSize:basic.iconsFontSize}}/>
                            </IconButton>
                            <IconButton
                                aria-label="Edit"
                                onClick={() => { 
                                    //localStorage.setItem("_accountId", params.row.id);                            
                                    __navigate(`/adminDashboard/customer/edit/${params.row.id}`);
                                }}
                            >
                                <EditIcon titleAccess='Edit Customer' title="Edit Customer" style={{fontSize:basic.iconsFontSize}} />
                            </IconButton>
                            <IconButton
                                disabled
                                aria-label="Delete"
                                onClick={() => { 
                                //__handleDeleteProduct(params.row._id,params.row.productName);
                                }}
                            >
                                <DeleteIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Delete Customer' title="Delete Customer" />
                            </IconButton>
                        </Box>
                    ),
                    },
                ]}
                disableSelectionOnClick
                autoHeight={true}
                checkboxSelection={false}
                selectionModel={__selecteCustomer}
                onSelectionModelChange={(selectedItems) => {
                    __selectedCustomerFunc(selectedItems)
                }}
                downloadXLFileName="customer_data"
                />
            </Box>
  
      </div>
    )
}
