import React, { useEffect, useState } from 'react'
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Box,
    IconButton,
    Modal,
    TextField,
    Button
  } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
    Delete as DeleteIcon, 
    PersonAddAlt as AssignUserIcon,
    Close as CloseIcon, Save, Check } from '@mui/icons-material';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from '../common/button_one';
import CustomButton2 from "../common/button_two";
import MessageSnackbar from '../common/message_snackbar';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import messages from "../../../themes/messages.json";
import UserModalComponent from './user_modal_component';
import LoadingOverlay from '../common/loading_overlay';

export default function Users() {
    const __location = useLocation();
    const __navigate = useNavigate();
    const [__loading,__setLoading] = useState(false);
    const [__customerAccountId, __setCustomerAccountId] = useState("");
    const [__customerUserData, __setCustomerUserDataData] = useState([]);
    const [__filteredCustomerData, __setFilteredCustomerData] = useState([]);
    const [__selectedRole, __setSelectedRole] = useState("All");
    const [__userModalOpen, __setUserModalOpen] = useState(false);
    const [__userActionMode, __setUserActionMode] = useState("add");
    const [__title, __setTitle] = useState("");
    const [__editUserSubscription,__setEditUserSubscription] = useState([]);
    const [__editUserId,__setEditUserId] = useState("");

    const [__snackbarOpen, __setSnackbarOpen] = useState(false);
    const [__snackbarMessage, __setSnackbarMessage] = useState("");
    const [__snackbarVariant, __setSnackbarVariant] = useState("success");

    const __userRoleOptions = [
      { value: "Teacher", label: "Teacher" },
      { value: "Student", label: "Student" },
      { value: "School Account", label: "School Account" },
    ];

    const __filterRoleOptions = [
      { value: "All", label: "All" },
      { value: "Teacher", label: "Teacher" },
      { value: "Student", label: "Student" },
      { value: "School Account", label: "School Account" },
    ];

    const [__userDetails, __setUserDetails] = useState({});
    const [__userEditDefaultDetails, __setUserEditDefaultDetails] = useState({});

    const [__grades, __setGrades] = useState([]);
    const [__currentGrade, __setCurrentGrade] = useState("");
    const [__isSaveEnabled, __setIsSaveEnabled] = useState(false);
    const [__errorsMsg, __setErrorsMsg] = useState({});

    const __handleSnackbarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      __setSnackbarOpen(false);
      __closeSingleUserModal();
    };

    const __triggerSnackbar = (message, variant) => {
        __setSnackbarMessage(message);
        __setSnackbarVariant(variant);
        __setSnackbarOpen(true);
    };

    const __handleUserRoleFilter = (event) => {
      const _role = event.target.value;
      __setSelectedRole(_role);
      if(_role == "All"){
        __setFilteredCustomerData(__customerUserData);
      }else{
        __setFilteredCustomerData(__customerUserData.filter(aUserData => aUserData.userRole.toLowerCase() ===_role.toLowerCase()));
      }
    }

    const __handleAddUser = () => {
      __setTitle("Add New User");
      __setSnackbarOpen(false);
      __setUserModalOpen(true);
      __setUserActionMode("add");
      __resetForm();
    }

    const __gradeConvertToArray = (aGradeValue) => {
      const _gradesArray = aGradeValue.split(",").map((grade) => grade.trim());
      return _gradesArray;
    }

    const __handleEditUser = async (aUserRowDetails) => {
      console.log(aUserRowDetails);
        __setSnackbarOpen(false);
         __setErrorsMsg({});
          __setLoading(true);  
          //__setLoadingGroup(false);

         
          const _userId = aUserRowDetails.id;
          __setEditUserId(_userId);

          const _getCustomerUserSubscriptionData = await axios.get(`/subscription/getCustomerUserSubscription/${_userId}`);

          const _userSubscripedGrades = await _getCustomerUserSubscriptionData?.data?.data?.subscriptionData.map(
            (item) => item.usergrade
          );
         if (_userSubscripedGrades) {
            __setEditUserSubscription(_userSubscripedGrades);
          }
          
          const _getUpdateGradeVal = __gradeConvertToArray(aUserRowDetails.grade);
          __setGrades(_getUpdateGradeVal);
          __setCurrentGrade(""); 

          const _groupValue = aUserRowDetails.group === "default-group" ? "" : aUserRowDetails.group;

          const _editUserFields = {
            userName:aUserRowDetails.userName,
            userEmail:aUserRowDetails.userEmail,
            userRole:aUserRowDetails.userRole,
            grade:_getUpdateGradeVal,
            dob:aUserRowDetails.dob,
            phonenumber:aUserRowDetails.phoneNum,
            customerAccountId:__customerAccountId,
            group:_groupValue
          };  
          __setUserDetails(_editUserFields);
          __setUserEditDefaultDetails(_editUserFields);

          const _title = `Edit User: ${aUserRowDetails.userName} (${aUserRowDetails.regNum}) `;
          __setUserActionMode("edit");
          __setTitle(_title);
          __setLoading(false);  
          __setUserModalOpen(true);
    }

    useEffect(()=> {
      if(__userActionMode =="edit"){
        if (__userEditDefaultDetails && __userDetails) {
        const _isFormModified = Object.keys(__userDetails).some(
          (key) => __userDetails[key] !== __userEditDefaultDetails[key]
        );
        __setIsSaveEnabled(!_isFormModified);
        }
      }else if (__userActionMode === "add") {
        __setIsSaveEnabled(false); // Always enable for Add mode
      }
    },[__userDetails, __userEditDefaultDetails, __userActionMode]);

    useEffect(() => {
      console.log("User: __userSubscriptionGrade updated:", __editUserSubscription);
    }, [__editUserSubscription]);

    const __closeSingleUserModal = () => {
      __setUserModalOpen(false);
    }

    const __handleAddGrade = () => {

      const _gradeRegex = /^[a-zA-Z0-9]+$/; // Allowed only numbers and letters.
      let __updatedGrades = [];     

      if (!_gradeRegex.test(__currentGrade)) {
        __setErrorsMsg((prevErrors) => ({
          ...prevErrors,
          grade: messages.customerUsersPage.gradeAlloweMsg
        }));
      }else if (
        __currentGrade.trim() !== "" &&
        !__grades.some((grade) => grade.toLowerCase() === __currentGrade.trim().toLowerCase())
      ) {
        if((__userDetails['userRole'] === "Student") && __grades.length >= 1){
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            grade: messages.customerUsersPage.gradeOneAllowedMsgUserRoleIsStudent
          }));
        }else{
          const _currentGrade = __currentGrade.trim();
          __updatedGrades = [...__grades, _currentGrade];
          __setGrades(__updatedGrades);
          __setCurrentGrade(""); 

          __setUserDetails((prev) => ({
          ...prev,
          grade: __updatedGrades
          }));
        }
      }else{
        __setErrorsMsg((prevErrors) => ({
          ...prevErrors,
          grade: messages.customerUsersPage.gradeAlreadyExistMsg
        }));
      }

      if (__updatedGrades.length > 0) {
        __setErrorsMsg((prev) => ({
          ...prev,
          grade: null
          }));
      }
    };

    const __handleRemoveGrade = (aGrade) => {
        const _updatedGrades = __grades.filter((grade) => grade !== aGrade);
        __setGrades(_updatedGrades);
        __setUserDetails((prev) => ({
          ...prev,
          grade: _updatedGrades
        }));
        if (_updatedGrades.length > 0) {
          __setErrorsMsg((prev) => ({
            ...prev,
            grade: null
            }));          
        }
    };

    const __inputValueChange = (aField, aValue) => {
        __setUserDetails(aPrev => ({
        ...aPrev,
        [aField]: aValue
        }))
    }

    const __resetForm = () => {
          __setUserDetails((prev) => ({
            ...prev,
            userName:"",
            userEmail:"",
            userRole:"Teacher",
            grade:[],
            dob:"",
            phonenumber:"",
            group:""
          }));
        __setErrorsMsg({});
        __setGrades([]);
        __setCurrentGrade("");
    };

    const __validateForm = async () => {
      const _emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const _phoneRegex = /^[0-9]{10}$/; // for 10-digit phone numbers
      const _nameRegex =  /^[a-zA-Z]+( [a-zA-Z]+)*$/; // Use only letters, with single spaces between words

      let __hasError = false;

      if(!__userDetails.userName){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userName: messages.customerUsersPage.usernameRequiredMsg,
          }));
          __hasError = true;
      }else if(!_nameRegex.test(__userDetails.userName)){
        __setErrorsMsg((aPrevError) => ({
          ...aPrevError,
          userName: messages.customerUsersPage.usernameFormatMsg,
        }));
        __hasError = true;
    }else{
          __setErrorsMsg((prevErrors) => {
            const { userName, ...restErrors } = prevErrors;
            return restErrors;
          });
      }

      

      if(!__userDetails['userEmail']){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userEmail: messages.customerUsersPage.emailRequiredMsg,
          }));
          __hasError = true;
      }else if(!_emailRegex.test(__userDetails.userEmail)){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userEmail: messages.customerUsersPage.emailFormatMsg,
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { userEmail, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if(!__userDetails['userRole']){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userRole: messages.customerUsersPage.roleRequiredMsg,
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { userRole, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if (__grades.length === 0) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            grade: messages.customerUsersPage.gradeRequiredOneMsg
          }));
          __hasError = true;
      }else{
        if((__userDetails['userRole'] == "Student") && __grades.length > 1){
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            grade: messages.customerUsersPage.gradeOneAllowedMsgUserRoleIsStudent
          }));
          __hasError = true;
        }else{
          __setErrorsMsg((prevErrors) => {
            const { grade, ...restErrors } = prevErrors; 
            return restErrors;
          });
          //__hasError = false;
        }
      }

      if (!__userDetails["dob"]) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            dob: messages.customerUsersPage.dobRequiredMsg
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { dob, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if (!__userDetails["phonenumber"]) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            phonenumber: messages.customerUsersPage.mobileNumRequiredMsg
          }));
          __hasError = true;
      }else if(!_phoneRegex.test(__userDetails.phonenumber)){
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            phonenumber: messages.customerUsersPage.mobileNumFormatMsg
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { phonenumber, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      console.log("handleSaveButton",__hasError);

      return !__hasError; // Return true if no errors

    }

    const __handleSaveButton = async () => {

      const _isFormValid = await __validateForm();

      console.log("handleSaveButton",_isFormValid);
      if (!_isFormValid) {
        return; 
      }

      try {

        if(__userActionMode === "add"){ // add new user
          const _addUser = await axios.post("/customerUsers/add", __userDetails);
          console.log(_addUser);

          if(_addUser.data.status == "success"){          
            __fetchCustomerUsersData(__customerAccountId);
            __closeSingleUserModal();
            __setSnackbarOpen(true);
            __triggerSnackbar(messages.customerUsersPage.newUserAddedSuccessMsg,"success"); 
            __resetForm();
          }else{
            __closeSingleUserModal();
            __setSnackbarOpen(true);
            __triggerSnackbar(messages.customerUsersPage.newUseraddedErrorMsg,"error"); 
          }

        }else if(__userActionMode === "edit"){ // edit user      

          const _updateUser = await axios.put(`/customerUsers/edit/${__editUserId}`, __userDetails);

          if(_updateUser.data.status == "success"){          
            __fetchCustomerUsersData(__customerAccountId);
            __closeSingleUserModal();
            __setSnackbarOpen(true);
            __triggerSnackbar(messages.customerUsersPage.editUserSuccessMsg,"success"); 
          }else{
            __closeSingleUserModal();
            __setSnackbarOpen(true);
            __triggerSnackbar(messages.customerUsersPage.editUserFailedMsg,"error"); 
          }
        }

      }catch(error){
        console.log("error form submit", error);
      }        
    }


    const __fetchCustomerUsersData = async (aCustomerAccountId) => {
      try{
        const _customerUsersData = await axios.post("/customerUsers/getUsersData",{ customerAccountId: aCustomerAccountId });
       __setCustomerUserDataData(_customerUsersData?.data?.data);
       __setFilteredCustomerData(_customerUsersData?.data?.data);
      }catch(aError){
          console.log("error fetch data", aError);
      }      
    }

    useEffect(() => {
      const _getCurrentCustomerId = localStorage.getItem("_accountId");
      if(_getCurrentCustomerId){
        __setCustomerAccountId(_getCurrentCustomerId);
        __setUserDetails((prev) => ({
          ...prev,
          customerAccountId: _getCurrentCustomerId
        }));
        __fetchCustomerUsersData(_getCurrentCustomerId);
      }
    }, []);

    
    return (
        <div>

        <LoadingOverlay loading={__loading} />

          { /* Add user or edit user popup start */}
          <UserModalComponent
            mode={__userActionMode}
            isOpen={__userModalOpen}
            editUserSubscription={__editUserSubscription}
            onClose={() => __closeSingleUserModal()}
            title={__title}
            userDetails={__userDetails}
            setUserDetails={__setUserDetails}
            errors={__errorsMsg}
            roleOptions={__userRoleOptions}
            grades={__grades}
            currentGrade={__currentGrade}
            setCurrentGrade={__setCurrentGrade}
            handleAddGrade={__handleAddGrade}
            handleRemoveGrade={__handleRemoveGrade}
            handleSaveSubmit={__handleSaveButton}
            //fetchingGroupSuggestions={__groupSuggestions}
            //setGroupSuggestions={__setGroupSuggestions}
            //loadingGroup={__loadingGroup}
            //handleSelectGroup={__handleSelectGroup}
            buttonEnabled={__isSaveEnabled}
            //setLoadingGroup={__setLoadingGroup}
            customerAccountId={__customerAccountId}
          />
          { /* Add user or edit user popup end  */}


          <Container fluid={true} style={{ padding:"0px"}}>          
              <Grid container spacing={0}>
                  <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"left", marginLeft:"0px", marginBottom:"20px", width:"100%", display:"block"}} variant="h4"><strong>Users</strong></Typography>
              </Grid>
              <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          height: "auto",
                          width: "100%",
                          ul: {
                            bgcolor: "#f6f6f6",
                            color: "#ffffff",
                          },
                        }}
                      >
                      <div style={{ display: "flex", position:"absolute", marginTop: 11,zIndex:"1000" }}>                            
                          <CustomButton2
                          aria-label="Add Users"
                          onClick={__handleAddUser}
                          >
                              <AddIcon fontSize={basic.headingFontSize} style={{ marginTop:-1 }} />
                              <div style={{ marginLeft: 5 }}>Add Users</div>
                          </CustomButton2>
                          <CustomButton aria-label="Import Users" disabled>
                              <AssignUserIcon fontSize={basic.headingFontSize}/>
                              <div style={{ marginLeft: 10 }}>Import Users</div>
                          </CustomButton>                          
                      </div>

                      <div style={{ display: "flex",  position:"absolute", marginTop: 11, zIndex:"1000", minWidth:"170px", right:"320px" }}>
                        <DropdownComponent
                        label="Filter by label"
                        value={__selectedRole}
                       // onChange={__handleUserRoleFilter}
                        onChange={(e) =>
                          __handleUserRoleFilter(e)
                        }
                        options={__filterRoleOptions}
                        />
                      </div>

                     {/*} <DropdownComponent /> { */}

                     {/*} <FormControl style={{ display: "flex",  position:"absolute", marginTop: 12,zIndex:"1000", right:"320px" }} sx={{ minWidth: 170, mb: 2, height:15 }}>
                            <InputLabel id="role-filter-label" 
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize, 
                                height:"35px", 
                                marginTop:"-8px", 
                                "&.Mui-focused": {
                                    marginTop:"3px !important",               
                                },
                                "&.MuiInputLabel-shrink": {
                                    marginTop:"3px !important",
                                    fontFamily:basic.fontFamily,
                                    fontSize:basic.labelFontSize,
                                    transform: "translate(13px, -12px) scale(1) !important",
                                    WebkitTransform: "translate(13px, -12px) scale(1) !important",
                                    MozTransform: "translate(13px, -12px) scale(1) !important",
                                    MsTransform: "translate(13px, -12px) scale(1) !important",
                                    OTransform: "translate(13px, -12px) scale(1) !important",
                                    color:basic.colorBlack,
                                },
                                "&.MuiOutlinedInput-root fieldset": {     
                                    borderColor: basic.logoGreyColor +" !important"                                   
                                }                                 
                            }}>
                                  Filter by Role
                            </InputLabel>
                            <Select
                              labelId='role-filter-label'
                              value={__selectedRole}
                              onChange={__handleUserRoleFilter}
                              label="Filter by label"
                              sx={selectBoxWithLabel}
                            >
                              <MenuItem 
                              value="All"
                              sx={{ 
                                  fontFamily:basic.fontFamily, 
                                  fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                All
                              </MenuItem>
                              <MenuItem 
                              value="Teacher"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                Teacher
                              </MenuItem>
                              <MenuItem value="Student"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                Student
                              </MenuItem>
                              <MenuItem value="School Account"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                School Account
                                </MenuItem>
                            </Select>
                      </FormControl> {*/}
                      

                      <DataGridStyle
                          rows={__filteredCustomerData}
                          columns={[
                          {
                              field: "group",
                              headerName: "Group",
                              hide: true,
                              disableExport: true,
                          },
                          {
                              field: "regNum",
                              headerName: "Reg. No",
                              //maxWidth:125,
                              flex: 1,
                              headerAlign: "left",
                          },
                          {
                              field: "userName",
                              headerName: "Name",
                              //maxWidth:120,
                              flex: 1,
                              headerAlign: "left",
                          },
                          {
                              field: "userEmail",
                              headerName: "Email",
                              flex: 2,
                              headerAlign: "left",
                          },
                          {
                            field: "userRole",
                            headerName: "Role",
                            //maxWidth:80,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "grade",
                            headerName: "Grade",
                            //maxWidth:90,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "dob",
                            headerName: "DOB",
                            //maxWidth:90,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "pin",
                            headerName: "PIN",
                            //maxWidth:80,
                            flex: 0.8,
                            headerAlign: "left",
                          },
                          {
                            field: "OTP",
                            headerName: "OTP",
                            //maxWidth:80,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "phoneNum",
                            headerName: "Mobile Number",
                            //maxWidth:110,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "actions",
                            headerName: "Actions",
                            flex: 1,
                            maxWidth: 90,
                            headerAlign: "left",
                            disableExport: true,
                            renderCell: (params) => (
                            <Box>
                                    <IconButton
                                      aria-label="Edit"
                                      onClick={() => __handleEditUser(params.row)}
                                    >
                                        <EditIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Edit' />
                                    </IconButton>                                

                                <IconButton
                                    disabled
                                    aria-label="Delete"
                                    //onClick={() => __handleDeleteSubscriptionOpen(params.row.id)}
                                    >
                                    <DeleteIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Delete' />
                                </IconButton>
                                
                            </Box>
                            ),
                        },                                  
                          ]}
                          disableSelectionOnClick
                          autoHeight={true}
                          export={true}
                          checkboxSelection={false}
                          downloadXLFileName="users_data"
                      />
                      </Box>
                  </Grid>
              </Grid>
          </Container>

          <MessageSnackbar
                message={__snackbarMessage}
                variant={__snackbarVariant}
                open={__snackbarOpen}
                onClose={__handleSnackbarClose}
            />
        </div >
    );

}