import React, { useState, useEffect } from 'react';
import { Box, IconButton, FormControlLabel, Alert, Add as AddIcons, Grid, Typography, Container, Skeleton } from '@mui/material';
import { Download as DownloadIcon, Layers as CategoryIcon} from '@mui/icons-material';
  
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DataGridStyle from './data-table/datagrid_style';
import { basic } from '../../themes/basic';
import LoadingOverlay from './common/loading_overlay';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import generateExcel from "../../components/Admin/common/generate_excel";

const Reports = () => {

  const [__productData, __setProductData] = useState([]);  
  const [__loading, __setLoading] = useState(false); 
  const __listData = [
    {name:"TaraV2_accountName_vs_userList_report", downloadFileName:"tara_v2_accountname_vs_userlist_report"},
    {name:"Total Users Count for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_total_user_count"},
    {name:"Unique Book Users for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_unique_book_user"},
    {name:"Unique Lesson Users for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_unique_lesson_user"},
    {name:"Total Book Session Count for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_total_book_session"},
    {name:"Total Lesson Session Count for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_total_lesson_session"},
    {name:"Completion Rate for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_completion_rate"},
    {name:"Return Rate for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_return_rate"},
    {name:"Correct Questions for Tara Fun Lesson",downloadFileName:"tara_fun_lesson_correct_questions"}
  ];

  const [__reportData, __setReportData] = useState(
    __listData.map((item, index) => ({
      id: index + 1,
      ...item,
    }))
  );


  const __handleDownload = async (aValue) => {

    if(aValue ==="tara_v2_accountname_vs_userlist_report"){
      __downloadExcelForTaraV2AccountNameVsUserList(aValue);
    }else{
      generateExcel(aValue);
    }
  }

  const __downloadExcelForTaraV2AccountNameVsUserList = async (aValue) => {

    try {
      __setLoading(true);
      const _response = await axios.get('https://skcq1bvj6h.execute-api.ap-southeast-1.amazonaws.com/default/plusTmsApSe1Lambda?env=prod');
      //console.log(_response);
      __setProductData(_response.data.Items);

        const dataToExport = [];

        _response.data.Items.forEach((user) => {
          user.person.forEach((person) => {
            dataToExport.push({
              "Account Name": user.name,
              "Email": user.email,
              "Role": user.role,
              'User Name': person.person_name,
              'User Role': person.person_role,
              'User Status': person.status ? 'Active' : 'Inactive',
              'User Pin': person.person_pin,
              //'User UUID': person.person_uuid,
            });
          });
        });

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, aValue+'.xlsx');
        __setLoading(false);

    }catch(error){
      __setLoading(false);
      console.error('Error fetching data or downloading Excel for TaraV2_accountName_vs_userList_report:', error);
    }
    
  };

  const __renderSkeletonRows = () => {
    return Array.from({ length: 1 }).map((_, index) => ({
      id: "",
      name: "",
      actions: "",
    }));
  };
  

  useEffect(() => {
    /*const __fetchData = async() => {
      try {
        __setLoading(true);
       // const _response = await axios.get('https://skcq1bvj6h.execute-api.ap-southeast-1.amazonaws.com/default/plusTmsApSe1Lambda?env=prod');
        //console.log(_response);
        //__setProductData(_response.data.Items);
        const __updatedListData = __reportData.map((item, index) => ({
          ...item,
          id: index + 1, 
        }));
        __setReportData(__updatedListData);
        __setLoading(false);
      }catch(aError) {
        console.error('Error fetching data:', aError);
        __setLoading(false);
      }
      
    }

    __fetchData(); */


   /* const __updatedListData = __reportData.map((item, index) => ({
      ...item,
      id: index + 1, 
    }));
    __setReportData(__updatedListData);*/
    
  }, []);

  

  return (
    <div style={{ marginTop: "0px", padding:"20px 0px 0px 0px", position:"relative" }}> 

      <LoadingOverlay loading={__loading} />

      <Grid  spacing={0}>
        <Grid container spacing={0}>
          <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"left", marginLeft:"0px", marginBottom:"20px", width:"100%", display:"block"}} variant="h4"><strong>Reports List</strong></Typography>
        </Grid> 

        <Box
        sx={{
          height: "auto",
          width: "100%",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
        >
        <DataGridStyle
          rows={__reportData}
          columns={[
            {
              field: "name",
              headerName: "Report Name",
              minWidth: 150,
              flex: 1,
              headerAlign: "left",
              renderCell: (params) => (
                <div style={{ marginLeft: 0, fontSize:basic.contentFontSize }}>{params.value}</div>
              ),
            },
          
            {
              field: "actions",
              headerName: "Actions",
              flex: 1,
              maxWidth: 120,
              headerAlign: "left",
              disableExport: true,
              sortable: false,
              renderCell: (params) => (
                
                <Box>
                  
                  <IconButton
                    aria-label="Download"
                    onClick={() => __handleDownload(params.row.downloadFileName)}
                  >
                    <DownloadIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Download' />
                  </IconButton>              
                </Box>
                
              ),
            },
          ]}
          disableSelectionOnClick
          autoHeight={true}
          showToolbar={false}
          loading={__loading}
          sx={{
            "& .MuiDataGrid-toolbarContainer": {
              display:"none",
            }
          }}
        />
        </Box>
      </Grid>
    </div>
  );
};

export default Reports;
