import React, { useEffect, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

import DetailsIcon from '@mui/icons-material/Details';
import SelectSchoolIcon from '@mui/icons-material/School';
import CategoryIcon from '@mui/icons-material/Category';
import AddAdminIcon from '@mui/icons-material/PersonAddAlt';
import UsersIcon from '@mui/icons-material/People';
import AdminList from '@mui/icons-material/List';
import { Report as ReportIcon }from '@mui/icons-material';

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import NavBar from '../../navBar';
import axios from 'axios';
import { getAllSchoolAccountDataByIdApi } from '../../../Services/api';
import useGlobalData from '../../../Zustand/GlobalData';
import LeftSidebar from '../common/left_sidebar';
import { activeStyle, hoverStyle, basic } from '../../../themes/basic';


export default function MainSchoolDashboard() {
    
    const [__selectedBtn, __setSelectedBtn] = useState();
    const { schoolData } = useGlobalData();
    const navigate = useNavigate();
    const location = useLocation();
    const { InitialUpdateAccountsData, usersData, userLicenses } = useGlobalData();

    const _customerMenuItems = [
        { name: "Profile", text: "Profile", icon: <DetailsIcon />, onClick: () => handleNavigation("profile") },
        { name: "users", text: "Users", icon: <UsersIcon />, onClick: () => handleNavigation("users") },
        { name: "subscription", text: "Subscription", icon: <CategoryIcon />, onClick: () => handleNavigation("subscription") },
        { name: "reports", text: "Usage Reports", icon: <ReportIcon />, onClick: () => handleNavigation("reports") },
        { disabled: true, name: "manageQuiz", text: "Manage Quiz", icon: <AddAdminIcon />, onClick: () => handleNavigation("manageQuiz") }
    ];

    useEffect(() => {
        __setSelectedBtn(location.pathname.split('/')[2])
        console.log("schoolDashboard",location.pathname.split('/')[2]);
    }, [location])

    const handleNavigation = (aNavigation) => {
        __setSelectedBtn(aNavigation); 
        navigate(aNavigation); 
    };


    useEffect(async () => {
        try {
            let accountID = localStorage.getItem("_accountId")
            let getAllSchoolDataById = await getAllSchoolAccountDataByIdApi(accountID)
            if (getAllSchoolDataById) {
                InitialUpdateAccountsData(getAllSchoolDataById)
                console.log("Gettting all data from mongodb", getAllSchoolDataById);
            }
        } catch (error) {

        }

        console.log("schoolData",schoolData);
    }, [])

    useEffect(() => {
        let isAdminLogged = localStorage.getItem("isAdminLogged");
        console.log(isAdminLogged);
        if (isAdminLogged === "false" || isAdminLogged === undefined) {
            navigate('/adminlogin')
        }
    }, [location])

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div style={{ marginTop: "50px" }}>

                <Container fluid={true}>
                    <Row className='d-flex'>
                        <Col className='d-flex p-0 flex-column lm-main-dashboard-school' style={{ backgroundColor: basic.buttonLightGrey, maxWidth:"220px" }}>
                            <div className='d-flex flex-column justify-content-between' style={{ marginTop:"10px"}} >
                                <LeftSidebar
                                    menuItems={_customerMenuItems}
                                    selectedBtn={__selectedBtn}
                                    activeStyle={activeStyle}
                                    hoverStyle={hoverStyle}
                                    selectedSchool={schoolData.schoolName}
                                />
                            </div>
                        </Col>
                        <Col style={{ marginLeft:"220px", marginTop:"20px"}}>
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
                
            </div>

        </div>
    )
}
