import React from "react";
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const GenerateExcel = async (dataType) => {
  try {

    // API Endpoints
    const endpoints = {
      totalUserCount: 'https://account-admin.learningmatters.ai/taralesson/users/count',
      uniqueBookUser: 'https://account-admin.learningmatters.ai/taralesson/sessions/unique-users/book',
      uniqueLessonUser: 'https://account-admin.learningmatters.ai/taralesson/sessions/unique-users/lesson',
      totalBookSession: 'https://account-admin.learningmatters.ai/taralesson/sessions/count/book',
      totalLessonSession: 'https://account-admin.learningmatters.ai/taralesson/sessions/count/lesson',
      completionRate: 'https://account-admin.learningmatters.ai/taralesson/completions',
      returnRate: 'https://account-admin.learningmatters.ai/taralesson/returns/returnrate',
      correctQuestions: 'https://account-admin.learningmatters.ai/taralesson/questions',
    };

    let dataToExport = [];

    // Fetch data based on `dataType`
    switch (dataType) {
      case 'tara_fun_lesson_total_user_count': {
        const response = await axios.get(endpoints.totalUserCount);
        const totalUserCount = response.data.data;
        dataToExport.push({ Name: 'Total User Count', Value: totalUserCount });
        break;
      }
      case 'tara_fun_lesson_unique_book_user': {
        const response = await axios.get(endpoints.uniqueBookUser);
        const uniqueBookUser = response.data.data[0];
        dataToExport.push({ Name: 'Unique Book User Count', Value: uniqueBookUser.uniqueUserCount });
        uniqueBookUser.userIds.forEach((userId, index) => {
            dataToExport.push({
              Name: `User ID ${index + 1}`, 
              Value: userId,
            });
        });
        break;
      }
      case 'tara_fun_lesson_unique_lesson_user': {
        const response = await axios.get(endpoints.uniqueLessonUser);
        const uniqueLessonUser = response.data.data[0];
        dataToExport.push({ Name: 'Unique Lesson User Count', Value: uniqueLessonUser.uniqueUserCount });
        uniqueLessonUser.userIds.forEach((userId, index) => {
            dataToExport.push({
              Name: `User ID ${index + 1}`, 
              Value: userId,
            });
        });
        break;
      }
      case 'tara_fun_lesson_total_book_session': {
        const response = await axios.get(endpoints.totalBookSession);
        const totalBookSession = response.data.data;
        dataToExport.push({ Name: 'Total Book Sessions Count', Value: totalBookSession });
        break;
      }
      case 'tara_fun_lesson_total_lesson_session': {
        const response = await axios.get(endpoints.totalLessonSession);
        const totalLessonSession = response.data.data;
        dataToExport.push({ Name: 'Total Lesson Sessions Count', Value: totalLessonSession });
        break;
      }
      case 'tara_fun_lesson_completion_rate': {
        const response = await axios.get(endpoints.completionRate);
        const completionRates = response.data.data;

        completionRates.forEach((rate) => {
            dataToExport.push({
              "Experience Type": rate.experience_type,
              "Lesson ID": rate.lesson_id,
              "Completion Rate": rate.completion_rate.toString(),
            });
          });
        break;
      }
      case 'tara_fun_lesson_return_rate': {
        const response = await axios.get(endpoints.returnRate);
        const returnRates = response.data.data;
        returnRates.forEach((rate) => {
          dataToExport.push({
            "Total Sessions":rate.total_sessions,
            "Experience Type": rate.experience_type,
            "Unique Users Count": rate.unique_users_count,
            "Return Rate": rate.return_rate.toString(),
          });
        });
        break;
      }
      case 'tara_fun_lesson_correct_questions': {
        const response = await axios.get(endpoints.correctQuestions);
        const correctQuestions = response.data.data;
        correctQuestions.forEach((question) => {
          dataToExport.push({            
            "Experience Type": question.experience_type,
            "Lesson ID": question.lesson_id,
            "Correct Questions": question.correct_questions.toString(),
            "Total Questions": question.total_questions.toString(),
            "_id": `Experience Type: ${question._id.experience_type}\nLesson ID: ${question._id.lesson_id}`,
          });
        });         
        break;
      }
      default: {
        throw new Error('Invalid dataType specified');
      }
    }

    // Convert to Excel
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { Report: worksheet }, SheetNames: ['Report'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save as File
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, `${dataType}.xlsx`);
  } catch (error) {
    console.error('Error generating Excel report:', error);
  }
};

export default GenerateExcel;
