import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Box, List, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse  } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School'; 
import { basic } from '../../../themes/basic';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";

const LeftSidebar = ({ menuItems, selectedBtn, activeStyle, hoverStyle, selectedSchool }) => {
    const [openMenu, setOpenMenu] = useState(null); // State to track open submenu

    const handleToggleSubMenu = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName); // Toggle submenu visibility
    };
    const _navigate = useNavigate();
    

    /*const [isBlinking, setIsBlinking] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 2000); 
  
      return () => clearInterval(interval);
    }, []); */


    return (
        <>
            {selectedSchool && (
                <Box 
                    sx={{
                        justifyContent: 'space-between',
                        padding: '0px 0px 10px 0px',
                        borderRadius: '0px',
                        marginBottom: '0px',
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box sx={{ display: "block", textAlign: "center", width: "100%" }}>
                        <SchoolIcon sx={{ color: basic.greenColor, marginRight: '8px' }} />
                        <Typography style={{color:basic.colorBlack, fontSize:basic.headingFontSize}} variant="subtitle1" fontWeight="bold" color="textPrimary">
                            {selectedSchool}
                        </Typography>
                    </Box>
                </Box>
            )}
            <List>
                {menuItems.map((item, index) => (
                    <div key={index}>
                        <ListItem  disablePadding>
                            <ListItemButton 
                                style={{ paddingLeft:"10px", paddingRight:"10px", ...(item.disabled && { color: "gray", pointerEvents: "none", opacity: 0.5 })}}
                                sx={selectedBtn === item.name ? activeStyle : hoverStyle}
                                /*onClick={() => {
                                    item.subMenuItems ? handleToggleSubMenu(item.name) : item.onClick();
                                }}*/
                                onClick={!item.disabled ? item.onClick : undefined}
                            >
                                <ListItemIcon style={{ fontSize:basic.iconsFontSize}}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                                {item.subMenuItems && (openMenu === item.name ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                            </ListItemButton>
                        </ListItem>
                        {item.subMenuItems && (
                            <Collapse in={openMenu === item.name} timeout="auto" unmountOnExit>
                                <List  disablePadding>
                                    {item.subMenuItems.map((subItem, subIndex) => (
                                        <ListItem key={subIndex} disablePadding>
                                            <ListItemButton 
                                                //sx={selectedBtn === item.name ? activeStyle : hoverStyle}
                                                sx={{ pl: 4 }} // Add padding to indent submenus
                                                onClick={subItem.onClick}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={subItem.text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </div>
                ))}
            </List>

            {selectedSchool && (

<Box 
                sx={{
                    padding: '0px 0px',
                    borderRadius: '0px',
                    position: "fixed",
                    bottom: "0px",
                    fontFamily:basic.fontFamily,
                    fontSize: basic.buttonFontSize,
                    backgroundColor: basic.logoGreyColor,
                    width: "220px",  
                    left: "0px",  
                    display: "flex", 

                }}
                >
                    <ListItemButton
                        onClick={() => {
                            _navigate("/adminDashboard/customer");
                        }}
                        title="EXIT ACCOUNT"
                        sx={hoverStyle}
                        style={{ 
                          display: "flex", 
                          padding: "10px 20px 10px 10px", 
                          borderRadius: "0px", 
                          //backgroundColor:isBlinking ? basic.greenColor : "transparent",
                          //color: isBlinking ? basic.colorWhite : basic.colorWhite, 
                          backgroundColor:"transparent",
                          color: basic.colorWhite, 
                          transition: "background-color 0.3s ease-in-out",
                        }}
                        
                    >
                            <ExitToAppIcon 
                                style={{ 
                                    fontSize:basic.buttonFontSize,
                                    color: basic.colorWhite, 
                                    marginRight: "10px", 
                                    marginTop:"-1px"
                                }} 
                            />
                            
                            <Typography 
                                style={{
                                    color: basic.colorWhite,
                                    fontSize: basic.buttonFontSize,  
                                    fontWeight: "500", 
                                    textTransform: "capitalize"
                                }}
                            >
                                EXIT ACCOUNT
                            </Typography>
                    </ListItemButton>
                </Box>
            )}
        </>
    );
};

export default LeftSidebar;
