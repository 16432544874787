import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../navBar";
import enableImg from '../Assets/enable.svg';
import disableImg from '../Assets/disable.svg';

import Step1_AddSchoolDetails from './Step1_AddSchoolDetails';
import Step2_AddUsers from "./Step2_AddUsers";
import closeBtnImg from '../Assets/closeBtnImg.svg';
import Loading from "../CmnCompnts/Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function Profile_Complete(props) {
    const [stepsCompleted, SetstepsCompleted] = useState(props.step);
    const [comptnStep, setComptnStep] = useState(0);
    const [schoolSubscriptions, setSchoolSubscriptions] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        SetstepsCompleted(comptnStep)
        console.log("current step", comptnStep);
    }, [comptnStep])

    useEffect(() => {
        const userEmail = localStorage.getItem("userEmail")
        if (userEmail) {
            axios.post("/customers/prflComptn", { userEmail: userEmail })
                .then((res, err) => {
                    if (res.data.userCompleteProfile <= 4) {
                        setComptnStep(res.data.userCompleteProfile)
                        setSchoolSubscriptions(res.data.schoolSubscriptions)
                    }
                    if(res.data.userCompleteProfile > 2){
                        navigate('/dashboard/user_dashboard')
                    }
                }).catch((err) => {
                    console.log("couldn't get the data");
                })
        }
        else {
            //navigate to login page
        }
    }, [])

    const OnStepsComplete = () => {
        console.log("All Steps completed", stepsCompleted)
        SetstepsCompleted(parseInt(stepsCompleted) + 1)
    }

    return (
        <div>
            <Grid container xs={8} style={{ margin: "0px auto", postion: "relative" }}>
                <Grid item xs={12} style={{ textAlign: "center", marginTop: "15px", fontSize: "1.5rem", color: "#707070" }} >
                    <div style={{ position: "relative" }} className="d-flex align-items-center justify-content-center">
                        <label>Please Complete Your Profile</label>
                        {/* <img src={closeBtnImg} width={"25px"} height={"25px"} style={{ position: "absolute", right: "10%" }} /> */}
                    </div>
                </Grid>

                <Grid container item xs={12} md={10} lg={8} style={{ textAlign: "center", margin: "0px auto", marginTop: "25px", fontSize: "1.5rem", color: "#707070" }} >
                    <Grid container item xs={4} >
                        <Grid item xs={12}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                <img src={stepsCompleted >= 0 ? enableImg : disableImg} width={"25px"} />
                                <div id="border" style={{ borderBottom: stepsCompleted == 2 ? "2px solid #8DB840" : "2px solid grey", width: "50%" }}></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ fontSize: "1rem", color: stepsCompleted == 1 ? "#8DB840" : "#707070" }} >
                            <p>
                                Step 1: <br />
                                Add School
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div id="border" style={{ borderBottom: stepsCompleted == 2 ? "2px solid #8DB840" : "2px solid grey", width: "50%" }}></div>
                            <img src={stepsCompleted > 2 ? enableImg : disableImg} width={"25px"} />
                            <div id="border" style={{ borderBottom: "2px solid grey", width: "50%" }}></div>
                        </div>
                        <Grid item xs={12} style={{ fontSize: "1rem" }}>
                            <p>
                                Step 2: <br />
                                Add Students & Teachers
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                            <div id="border" style={{ borderBottom: "2px solid grey", width: "50%" }}></div>
                            <img src={stepsCompleted > 3 ? enableImg : disableImg} width={"25px"} />
                        </div>
                        <Grid item xs={12} style={{ fontSize: "1rem" }}>
                            <p>
                                Step 3: <br />
                                Admin Verification
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    {stepsCompleted == 3 ? <h6><b>Your account will be verified within 24 hours.</b></h6> : ""}
                </Grid>
            </Grid>
            {stepsCompleted == 0 ? <Loading /> : ""}
            {stepsCompleted == 1 ? <Step1_AddSchoolDetails OnStepsComplete={OnStepsComplete} /> : ""}
            {stepsCompleted == 2 ? <Step2_AddUsers OnStepsComplete={OnStepsComplete} schoolSubscriptions={schoolSubscriptions} /> : ""}
        </div>
    )
}

export default Profile_Complete;