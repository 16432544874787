import React, { useCallback, useEffect, useState } from "react";
import { Grid, TextField, Button, Checkbox, FormGroup, FormControlLabel, autocompleteClasses } from '@mui/material'
import { Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useDropzone } from 'react-dropzone'
import Papa from "papaparse";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import PrmCheckBox from "../CmnCompnts/CheckBox";
import CSVWithRegNum from '../Assets/CSVFile/SampleCSVWithReg_No.csv'
import CSVWithoutRegNum from '../Assets/CSVFile/SampleCSVWithoutReg_No.csv'

function Step2_AddUsers(props) {
    const navigate = useNavigate();

    const [inputField, setInputField] = useState({});
    const [onAddClick, SetonAddClick] = useState(false);
    const [csvData, SetCsvData] = useState([]);
    const [csvFileTxt, SetCsvFileTxt] = useState("Click to select file or drag-and-drop the file here!!");
    const [teachersData, SetTeachersData] = useState([]);
    const [adminData, SetAdminData] = useState([]);
    const [studentsData, SetStudentsData] = useState([]);
    const [crntData, SetCrntData] = useState("admins");
    const [autoGenerate, SetAutoGenerate] = useState(false);

    const OnAdd = (data) => {
        if (csvData.length > 0) {
            autoGenerate ? autoGenerateUsers(csvData, 3) : dfltGenerateUsers(csvData, 4);
            // SetonAddClick(true)
            // let admins = [];
            // let teachers = [];
            // let students = [];
            // const uniqueIds = [];
            // const filteredData = csvData.filter(element => {
            //     const isDuplicate = uniqueIds.includes(element[0]);
            //     if (!isDuplicate) {
            //         uniqueIds.push(element[0]);
            //         return true;
            //     }
            //     return false;
            // });
            // console.log("csv Data", filteredData);
            // const remvFirstArr = filteredData.slice(1)
            // let school_code = "KAR" + "BLR" + "SCHL";
            // let roleNum = autoGenerate ? 3 : 4;

            // remvFirstArr.map((dt, key) => {
            //     if (dt[roleNum] == "student") {
            //         const userDtWithFields = { regNum: autoGenerate ? `${school_code + "_" + key}` : dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
            //         students.push(userDtWithFields)
            //     }
            //     if (dt[roleNum] == "teacher") {
            //         const userDtWithFields = { regNum: autoGenerate ? `${school_code + "_" + key}` : dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
            //         teachers.push(userDtWithFields)
            //     }
            //     if (dt[roleNum] == "admin") {
            //         const userDtWithFields = { regNum: autoGenerate ? `${school_code + "_" + key}` : dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
            //         admins.push(userDtWithFields)
            //     }
            // })
            // SetTeachersData(teachers)
            // SetAdminData(admins)
            // SetStudentsData(students)
        }
    }

    const autoGenerateUsers = (data, roleNum) => {
        SetonAddClick(true)
        let admins = [];
        let teachers = [];
        let students = [];
        const uniqueIds = [];
        const remvFirstArr = csvData.slice(1)
        let school_code = "KAR_" + "BLR_" + "SCHL_";
        remvFirstArr.map((dt, key) => {
            if (dt[roleNum] == "student") {
                const userDtWithFields = { regNum: `${school_code + key}`, firstname: dt[0], lastname: dt[1], email: dt[2], role: dt[3], grade: dt[4], dob: dt[5], phoneNum: dt[6] }
                students.push(userDtWithFields)
            }
            if (dt[roleNum] == "teacher") {
                const userDtWithFields = { regNum: `${school_code + key}`, firstname: dt[0], lastname: dt[1], email: dt[2], role: dt[3], grade: dt[4], dob: dt[5], phoneNum: dt[6] }
                teachers.push(userDtWithFields)
            }
            if (dt[roleNum] == "admin") {
                const userDtWithFields = { regNum: `${school_code + key}`, firstname: dt[0], lastname: dt[1], email: dt[2], role: dt[3], grade: dt[4], dob: dt[5], phoneNum: dt[6] }
                admins.push(userDtWithFields)
            }
        })
        SetTeachersData(teachers)
        SetAdminData(admins)
        SetStudentsData(students)
    }

    const dfltGenerateUsers = (data, roleNum) => {
        SetonAddClick(true)
        let admins = [];
        let teachers = [];
        let students = [];
        const uniqueIds = [];
        const filteredData = csvData.filter(element => {
            const isDuplicate = uniqueIds.includes(element[0]);
            if (!isDuplicate) {
                uniqueIds.push(element[0]);
                return true;
            }
            return false;
        });
        console.log("csv Data", filteredData);
        const remvFirstArr = filteredData.slice(1)
        let school_code = "KAR" + "BLR" + "SCHL";
        remvFirstArr.map((dt, key) => {
            if (dt[roleNum] == "student") {
                const userDtWithFields = { regNum: dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
                students.push(userDtWithFields)
            }
            if (dt[roleNum] == "teacher") {
                const userDtWithFields = { regNum: dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
                teachers.push(userDtWithFields)
            }
            if (dt[roleNum] == "admin") {
                const userDtWithFields = { regNum: dt[0], firstname: dt[1], lastname: dt[2], email: dt[3], role: dt[4], grade: dt[5], dob: dt[6], phoneNum: dt[7] }
                admins.push(userDtWithFields)
            }
        })
        SetTeachersData(teachers)
        SetAdminData(admins)
        SetStudentsData(students)
    }

    const onDrop = useCallback(acceptedFiles => {
        console.log("Now you can do anything with" +
            " this file as per your requirement", acceptedFiles[0].path)
        SetCsvFileTxt(`Added File Name ${acceptedFiles[0].path}`)

        Papa.parse(acceptedFiles[0], {
            complete: results => {
                // SetCsvData(results.data)
                SetCsvData(oldDt => [...oldDt, ...results.data])
            }
        })
    }, [])

    const { getInputProps, getRootProps } = useDropzone({ onDrop })

    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        props.OnStepsComplete()
        let data = {
            _id: localStorage.getItem("lmAccountId"),
            admins: adminData,
            teachers: teachersData,
            students: studentsData,
            sessionEmail: localStorage.getItem("userEmail")
        }
        axios
            .post("/customerUsers/addAccountUsers", data)
            .then((res) => {
                console.log("Users Addedd Successfully")
                localStorage.setItem("usersAdded", "true")
                navigate('/dashboard/user_dashboard')
            })
            .catch((error) => console.log(error));
    }

    const columns = [
        { field: 'id', headerName: 'Reg No.', width: 150 },
        { field: 'firstName', headerName: 'First name', width: 150 },
        { field: 'lastName', headerName: 'Last name', width: 150 },
        { field: 'email', headerName: 'Email-ID', width: 250 },
        { field: 'role', headerName: 'Role', width: 90, },
        { field: 'grade', headerName: 'Grade', width: 50 },
        { field: 'dob', headerName: 'DOB', width: 100 },
        { field: 'phoneNum', headerName: 'Phone No.', width: 150 },
        {
            field: 'products',
            headerName: 'Products',
            width: 255,
            renderCell: (params) => (
                <div className="d-flex flex-row">
                    {
                        Object.keys(props.schoolSubscriptions).map((dt, key) => {
                            return <span>
                                <Checkbox defaultChecked /> {`${dt}`}
                            </span>
                        })
                    }
                </div>
            ),
        },
    ];

    const teachersRow = teachersData.map((dt, key) => {
        return { id: dt.regNum, firstName: dt.firstname, lastName: dt.lastname, email: dt.email, role: dt.role, grade: dt.grade, dob: dt.dob, phoneNum: dt.phoneNum, products: "Tara, Quiz" }
    });

    const TeachersTable = () => {
        return (
            <div style={{ height: 400, width: '100%' }}>
                {/* <DataGrid
                    rows={teachersRow}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                // checkboxSelection
                /> */}
                {
                    teachersData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                        <thead>
                            <tr>
                                <th>Reg No.</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Email-ID</th>
                                <th>Role</th>
                                <th>Grade</th>
                                <th>DOB</th>
                                <th>Phone No.   </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teachersData.length > 0 ? teachersData.map((dt, i) => {
                                    return <tr>
                                        <td>{dt.regNum}</td>
                                        <td>{dt.firstname}</td>
                                        <td>{dt.lastname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.role}</td>
                                        <td>{dt.grade}</td>
                                        <td>{dt.dob}</td>
                                        <td>{dt.phoneNum}</td>
                                    </tr>
                                }) : "Loading"
                            }
                        </tbody>
                    </Table> : "No Teachers Added"
                }
            </div>
        );
    }

    const studentsRow = studentsData.map((dt, key) => {
        // return { regNum: (key + 1), firstName: dt.firstname, lastName: dt.lastname, email: dt.email, role: dt.role }
        return { id: dt.regNum, firstName: dt.firstname, lastName: dt.lastname, email: dt.email, role: dt.role, grade: dt.grade, dob: dt.dob, phoneNum: dt.phoneNum, products: "Tara, Quiz" }
    });


    const StudentsTable = () => {
        return (
            <div style={{ height: 400, width: '100%' }}>
                {/* <DataGrid
                    rows={studentsRow}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                // checkboxSelection
                /> */}
                {
                    studentsData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                        <thead>
                            <tr>
                                <th>Reg No.</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Email-ID</th>
                                <th>Role</th>
                                <th>Grade</th>
                                <th>DOB</th>
                                <th>Phone No.</th>
                            </tr>
                        </thead>
                        <tbody className="w-100">
                            {
                                studentsData.length > 0 ? studentsData.map((dt, i) => {
                                    console.log("Ssssssss", dt);
                                    return <tr>
                                        <td>{dt.regNum}</td>
                                        <td>{dt.firstname}</td>
                                        <td>{dt.lastname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.role}</td>
                                        <td>{dt.grade}</td>
                                        <td>{dt.dob}</td>
                                        <td>{dt.phoneNum}</td>
                                    </tr>
                                }) : "Loading"
                            }
                        </tbody>
                    </Table> : "No Students Added"
                }
            </div>
        );
    }

    const adminsRow = adminData.map((dt, key) => {
        // return { regNum: (key + 1), firstName: dt.firstname, lastName: dt.lastname, email: dt.email, role: dt.role }
        // return { id: (key + 1), firstName: dt[0], lastName: dt[1], email: dt[2], role: dt[3] }
        return { id: dt.regNum, firstName: dt.firstname, lastName: dt.lastname, email: dt.email, role: dt.role, grade: dt.grade, dob: dt.dob, phoneNum: dt.phoneNum, products: "Tara, Quiz" }
    });

    const AdminsTable = () => {
        return (
            <div style={{ height: 400, width: '100%' }}>
                {/* <DataGrid
                    rows={adminsRow}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                // checkboxSelection
                /> */}
                {
                    adminData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                        <thead>
                            <tr>
                                <th>Reg No.</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Email-ID</th>
                                <th>Role</th>
                                <th>Grade</th>
                                <th>DOB</th>
                                <th>Phone No.   </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                adminData.length > 0 ? adminData.map((dt, i) => {
                                    return <tr>
                                        <td>{dt.regNum}</td>
                                        <td>{dt.firstname}</td>
                                        <td>{dt.lastname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.role}</td>
                                        <td>{dt.grade}</td>
                                        <td>{dt.dob}</td>
                                        <td>{dt.phoneNum}</td>
                                    </tr>
                                }) : "Loading"
                            }
                        </tbody>
                    </Table> : "No Admins Added"
                }
            </div>
        );
    }

    const showClikdData = (e) => {
        SetCrntData(e.target.id)
    }


    return (
        <Container style={{ backgroundColor: "white", borderRadius: "5px" }}>
            {
                onAddClick ? <div>

                    <Row>
                        <Col style={{ textAlign: "start", marginTop: "15px" }} lg={10}>
                            <Button onClick={showClikdData} id={"admins"} style={{ border: "1px solid grey", margin: "5px", color: "black", backgroundColor: crntData == 'admins' ? "#8dba23" : "white" }}>Admins</Button>
                            <Button onClick={showClikdData} id={"teachers"} style={{ border: "1px solid grey", margin: "5px", color: "black", backgroundColor: crntData == 'teachers' ? "#8dba23" : "white" }}>Teachers</Button>
                            <Button onClick={showClikdData} id={"students"} style={{ border: "1px solid grey", margin: "5px", color: "black", backgroundColor: crntData == 'students' ? "#8dba23" : "white" }}>Students</Button>
                        </Col>
                        <Col lg={2}>
                            <Button variant={"contained"} style={{ backgroundColor: "#90BB41", borderRadius: "0px", minWidth: "150px" }} onClick={onSubmit}> Add </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "25px" }}>
                        <Col>
                            {crntData == "admins" ? <AdminsTable /> : ""}
                            {crntData == "students" ? <StudentsTable /> : ""}
                            {crntData == "teachers" ? <TeachersTable /> : ""}
                        </Col>
                    </Row>
                    <div>
                        <Row style={{ display: 'flex', padding: 30, textAlign: "center" }}>
                            <Col md={8} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
                                <div {...getRootProps()} style={{ marginTop: "15px" }}>
                                    <input {...getInputProps()} />
                                    <p className="dropZoneArea">{csvFileTxt}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "15px", textAlign: "center", justifyContent: "center" }}>
                            <Col lg={4}>
                                <Button variant={"contained"} style={{ backgroundColor: "#90BB41", borderRadius: "0px", minWidth: "200px" }} onClick={OnAdd}> SUBMIT </Button>
                            </Col>
                        </Row>
                    </div>
                </div> : <div>
                    <Row>
                        <Col className="mt-3">
                            <div style={{ display: "flex", fontSize: "20px" }}>
                                <PrmCheckBox label={"Auto generate Registeration No."} onChange={(e) => SetAutoGenerate(e.target.checked)} />
                                <a style={{ marginLeft: "15px" }} download={`Excel Sheet.csv`} href={`${autoGenerate ? CSVWithoutRegNum : CSVWithRegNum}`}> Download Excel Sheet</a>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ display: 'flex', padding: 30, textAlign: "center" }}>
                        <Col md={8} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
                            <div {...getRootProps()} style={{ marginTop: "15px" }}>
                                <input {...getInputProps()} />
                                <p className="dropZoneArea">{csvFileTxt}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "15px", textAlign: "center", justifyContent: "center" }}>
                        <Col lg={4}>
                            <Button variant={"contained"} style={{ backgroundColor: "#90BB41", borderRadius: "0px", minWidth: "200px" }} onClick={OnAdd}> SUBMIT </Button>
                        </Col>
                    </Row>
                </div>
            }
            {/* <Row>
                <Col md={5} style={{ margin: "0px auto", marginTop: "10px", display: "flex" }}>
                    <button value={"DragDrop"} className={"selectBtns"} style={{ margin: "10px" }}>Drag & Drop</button>
                    <button value={"Manual"} className={"selectBtns"} style={{ margin: "10px" }}>Add Manually</button>
                </Col>
            </Row> */}
            <br />
            <br />
        </Container>
    )
}

export default Step2_AddUsers;