import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { Box, Grid, useMediaQuery  } from "@mui/material";
import PasswordModal from "../Admin/PasswordCompnts/change_password";
import { basic } from '../../themes/basic';
import InputTextFieldComponent from "./common/input_text_field";
import PageHeading from "./page_heading";
import CustomButton from "./common/button_two";
import { Save, Login } from '@mui/icons-material';
import LogoImage from '../../../src/assets/images/logo.png';
import bgImg from "./../../components/Assets/authoring-bg.jpg";
import messages from "../../themes/messages.json";
import LeftPanelImage from "../../../src/assets/images/login-page.jpg"

function AdminLogin(props) {
    const navigate = useNavigate();
    const [__error, __setError] = useState("");

    const [__showModal, __setShowModal] = useState(false);
    const [__modalType, __setModalType] = useState("forgotPassword");
    const [__inputField, __setInputField] = useState({
        user_email: "",
        user_password: "",
    });
    const [__errorsMsg, __setErrorsMsg] = useState({});

    const __isSmallScreen = useMediaQuery("(max-width:900px)");


    const __onChangeTxt = (e) => {
        __setInputField({
            ...__inputField,
            [e.target.name]: e.target.value,
        });
        __setError("")
    };

    const __handleOpenModal = (type) => {
        __setModalType(type);
        __setShowModal(true);
    };

    const handleCloseModal = () => {
        __setShowModal(false);
    };

    //Admin login
    const __onSubmitBtn = async (e) => {
        __setErrorsMsg({});
        e.preventDefault();
        const _loginData = {
            userEmail: __inputField.user_email,
            userPassword: __inputField.user_password
        }

        let __hasError = false;

        if(!__inputField.user_email){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                email: messages.adminLoginPage.emailMsg
              }));
            //__setError(messages.adminLoginPage.emailMsg);
            __hasError = true;
            return;
        }

        if(!__inputField.user_password){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                password: messages.adminLoginPage.passwordMsg
              }));
            //__setError(messages.adminLoginPage.passwordMsg);
            __hasError = true;
            return;
        }

        if(!__hasError){

            await axios.post("/admin/login", _loginData)
                .then((res) => {
                    if (res.data.isAdminVerified) {
                        localStorage.setItem("isAdminLogged", "true");
                        localStorage.setItem("adminId", res.data.adminId);
                        navigate('/adminDashboard')
                    }
                    else {
                        if(res.data.status == "errorPwd"){
                            __setErrorsMsg((prevErrors) => ({
                                ...prevErrors,
                                password: res.data.message
                              }));
                        }else  if(res.data.status == "errorEmail"){
                            __setErrorsMsg((prevErrors) => ({
                                ...prevErrors,
                                email: res.data.message
                              }));
                        }else{
                            __setError(res.data.message);
                        }
                    }

                })
                .catch((err) => {
                    __setError("Email and password are required.");
                    setTimeout(() => {
                        __setError("")
                    }, 3000);
                })
        }
    };


    return (
        <div style={{  
                padding:"20px 0px", 
                minHeight: "100vh", 
                background: `url(${bgImg})`, 
                backgroundSize: "cover" 
            }}
        >
            {/* Logo Section */}
            <Grid container style={{ margin:"auto", textAlign:"center", display:"block", marginTop:"10px"}}>
                 <img src={LogoImage} alt="logoImg" style={{ width: "150px", marginRight: "0px" }}></img>
            </Grid>

            {/* Main Container */}
            <Grid 
                container
                sx={{ 
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: basic.colorWhite,
                    width: "80%",
                    height:"90%",
                    maxWidth: "1000px",
                    margin: "30px auto 0px auto",
                    overflow: "hidden",
                    padding:4,
                }}
            >   
                {/* Left Panel - Image */}

                {!__isSmallScreen && (
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        borderRight:"1px solid #ccc"
                      }}
                >
                    <img
                    src={LeftPanelImage}
                    alt="Admin Login"
                    style={{
                        maxWidth: "100%",
                        maxHeight: "300px",
                        objectFit: "contain",
                    }}
                    />
                </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    md={__isSmallScreen ? 12 : 6} // Full width on small screens
                    //container
                    //alignItems="center"
                    //justifyContent="center"
                    style={{
                        padding: "35px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                >
                    <Box
                    sx={{
                        width: '100%',
                        maxWidth: "100%",
                        padding: 0,
                        textAlign: 'center',
                    }}
                    >
                        <PageHeading title="ADMIN LOGIN" alignment="center" fontSize="22px" />
                        
                        {/* Username Field */}
                        <InputTextFieldComponent 
                            required
                            size="medium"
                            name="user_email"
                            label="Email" 
                            value={__inputField.user_email}
                            variant="outlined" 
                            style={{ width: "100%", margin:"30px 0px 20px 0px" }} 
                            onChange={__onChangeTxt}
                            error={Boolean(__errorsMsg.email)}
                            helperText={__errorsMsg.email || ""}
                        />
                                               
                        {/* Password Field */}
                        <InputTextFieldComponent 
                            required
                            size="medium"
                            name="user_password"
                            type="password"
                            value={__inputField.user_password}
                            label="Password" 
                            variant="outlined" 
                            style={{ width: "100%",margin:"5px 0px 0px 0px" }} 
                            onChange={__onChangeTxt}
                            error={Boolean(__errorsMsg.password)}
                            helperText={__errorsMsg.password || ""}
                        />   

                        {/* Forgot Password */}
                        <Grid container style={{ margin:"auto", textAlign:"right", display:"block", margin:"0px 0px 10px 0px"}}>
                            <button className="txt-btn-link" title="Forgot Password" onClick={(e) => {
                                e.preventDefault()
                                __handleOpenModal("forgotPassword");
                            }}>
                                Forgot Password
                            </button>
                        </Grid>

                        {/* Login Button */}
                        <CustomButton
                        onClick={__onSubmitBtn}
                        aria-label="LOGIN"
                        title="LOGIN"
                        >
                            <Login fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                            <div style={{ marginLeft: 5 }}>LOGIN</div>
                        </CustomButton>

                        {/* Error Message */}
                        {__error && (
                            <div
                            className="text-center"
                            style={{
                                marginTop: "10px",
                                color: "red",
                                fontFamily: basic.fontFamily,
                                fontSize: basic.contentFontSize,
                            }}
                            >
                            {__error}
                            </div>
                        )}
                        
                    </Box>
                </Grid>
            </Grid>
            
            {/* Forgot Password Popup */}
            <PasswordModal
                show={__showModal}
                actionType={__modalType}
                onClose={handleCloseModal}
            />
            
        </div>
    );
}

export default AdminLogin;
