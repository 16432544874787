import axios from "axios"

// export const getAllSchools = async () => {
//     console.log("-------------");
//     let dt = await axios
//         .get("/quiz/getAllSchools")
//         .then((res) => {
//             return res.data
//         })
//         .catch(
//         // (error) => setIsError(error.message)
//     )
//     return dt;
// }

export const getAllSchools = async () => {
    console.log("-------------");
    let dt = await axios
        .get("/quiz/getAllSchools")
        .then((res) => {
            console.log("Ssssssssss", res.data);
            return res.data
        })
        .catch((err => {
            console.log("rrrrrrrrrrrrrrrrrrrrr", err);
        }))
    return dt;
}


export const getAllAccountQuizData = async () => {
    let dt = await axios.get("/quiz/getAllQuiz").then((res) => {
        return res.data
    })
        .catch((err) => {
            console.log("Couldn't get the quiz list", err);
        })
    return dt
}

export const getAllQuestionBankSets = async () => {
    console.log("getAllQuestionBankSets()");
    let dt = await axios
        .get("/questionBank/")
        .then((res) => {
            console.log("all question bank set data", res.data);
            return res.data
        })
        .catch(
        // (error) => setIsError(error.message)
    )
    return dt;
}

export const gets3Image = async (imagename) => {
    // console.log("bbbbbbbbb", imagename);
    let dt = await axios
        .post("/quiz/getQuizImage", { imagename: imagename })
        .then((res) => {
            console.log("image data", res.data);
            const buffer = res.data;
            const blob = new Blob([buffer], { type: 'image/png' });
            const url = URL.createObjectURL(blob);
            // var str = res.data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
            return Buffer.from(res.data.data).toString('base64');
            // return Buffer.from(res.data.data).toString('base64');
            // return url
        })
        .catch(
        // (error) => setIsError(error.message)
    )
    return dt;
}

export const getQuestionBankSetById = async (id) => {
    console.log("getQuestionBankSetById ");
    let dt = await axios
        .post("/questionBank/getQuestionBankById", { questionId: id })
        .then((res) => {
            console.log("all question bank set data", res.data);
            return res.data
        })
        .catch(
            (error) => error.message
        )
    return dt;
}

export const getAllSchoolUsers = async (id) => {
    let dt = await axios
        .post("/quiz/getAllSchoolUsers", { id: id })
        .then((res) => {
            return res.data.users
        })
        .catch(
        // (error) => setIsError(error.message)
    )
    return dt;
}



export const getAllSchoolAccountDataByIdApi = async (id) => {
    let payload = {
        id: id
    }

    return new Promise((resolve, reject) => {
        axios.post(`/customers/getAllDataById`, payload)
            .then((res) => {
                resolve(res.data.result);
            })
            .catch((err) => {
                console.log("Error ", err);
                reject(err);
            })
    })
}

export const apiProvider = {
    getAllSchoolUsers,
    getAllSchools
}