import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';

const DropdownComponent = ({
  label,
  value,
  onChange,
  options = [],
  error = false,
  helperText = "",
  required = false,
  sx = {},
  ...rest
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      error={error}
      sx={sx}
    >
      {label && 
      <InputLabel 
        sx={{ 
            fontFamily:basic.fontFamily, 
            fontSize:basic.inputFieldDefaultFontSize, 
            height:"35px", 
            marginTop:"-8px", 
            "&.Mui-focused": {
                marginTop:"3px !important",               
            },
            "&.MuiInputLabel-shrink": {
                marginTop:"3px !important",
                fontFamily:basic.fontFamily,
                fontSize:basic.labelFontSize,
                transform: "translate(13px, -12px) scale(1) !important",
                WebkitTransform: "translate(13px, -12px) scale(1) !important",
                MozTransform: "translate(13px, -12px) scale(1) !important",
                MsTransform: "translate(13px, -12px) scale(1) !important",
                OTransform: "translate(13px, -12px) scale(1) !important",
                color:basic.colorBlack,
                backgroundColor: "transparent",
                paddingRight:"5px",
                position:"absolute",
                backgroundColor:"#fff",
                zIndex:"10000",
                height:"22px"
            },
            "&.MuiOutlinedInput-root fieldset": {     
                borderColor: basic.logoGreyColor +" !important"                                   
            }, 
            
        }}>
        {label}
      </InputLabel>}
      <Select
        value={value}
        onChange={onChange}
        label={label}
        sx={selectBoxWithLabel}
        {...rest} // Pass remaining props dynamically
      >
        {options.map((option, index) => (
          <MenuItem 
          sx={{ 
            fontFamily:basic.fontFamily, 
            fontSize:basic.inputFieldDefaultFontSize
          }}
            key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText 
      style={{ 
        fontFamily:basic.fontFamily,
        fontSize:basic.errorMsgFontSize,
        margin:"0px 0px 0px 0px"
      }}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default DropdownComponent;
