import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Typography, CircularProgress, Box, TextField, Button } from "@mui/material";
import LogoImage from "../../../../src/assets/images/logo.png";
import bgImg from "./../../../components/Assets/authoring-bg.jpg";

export default function CustomerApproveReject() {
    const [statusMessage, setStatusMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryType = queryParams.get("type");
        const queryConfirmationCode = queryParams.get("code");

        if (!queryType || !queryConfirmationCode) {
            setStatusMessage("Missing required query parameters.");
            setLoading(false);
            return;
        }

        setType(queryType);
        setConfirmationCode(queryConfirmationCode);

        if (queryType === "approve" || queryType === "reject") {
            validateAndApprove(queryType, queryConfirmationCode);
        } else {
            setStatusMessage("Invalid type parameter.");
            setLoading(false);
        }
    }, []);

    const validateAndApprove = async (type, confirmationCode) => {
        try {
            const response = await axios.post("/customers/approve-reject", {
                type,
                confirmationCode,
            });
            const actionMessage =
                type === "approve"
                    ? "Customer details reject status is updated."
                    : "Customer details approved status is updated.";
            setStatusMessage(response.data.message || actionMessage);
            setStatusMessage(response.data.message);
        } catch (error) {
            setStatusMessage(
                error.response?.data?.message || "An error occurred. Please try again."
            );
        } finally {
            setLoading(false);
        }
    };


    return (
        <div
            style={{
                padding: "20px 0px",
                minHeight: "100vh",
                background: `url(${bgImg})`,
                backgroundSize: "cover",
            }}
        >
            {/* Logo Section */}
            <Grid container style={{ margin: "auto", textAlign: "center", display: "block", marginTop: "10px" }}>
                <img src={LogoImage} alt="logoImg" style={{ width: "150px", marginRight: "0px" }} />
            </Grid>

            {/* Main Container */}
            <Grid
                container
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: "#fff",
                    width: "80%",
                    maxWidth: "1000px",
                    margin: "70px auto 0px auto",
                    overflow: "hidden",
                    padding: 4,
                    textAlign: "center",
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Typography
                        variant="h5"
                        style={{
                            color: statusMessage.includes("error") ? "red" : "green",
                            fontWeight: "bold",
                            width:"100%",
                            textAlign:"center"
                        }}
                    >
                        {statusMessage}
                    </Typography>
                )}
            </Grid>
        </div>
    );
}
