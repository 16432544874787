
import { useEffect, useState } from "react";
import React from "react";
import { Autocomplete, Button, Grid, TextField,Box, Typography } from "@mui/material";
// import logoImg from "../Assets/logo.png";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { basic, textFieldAndShrinkStyle } from "../../themes/basic";
import ButtonComponent from "../Admin/common/button_two";
import { NavigateNext as NavigateNextIcon, Login as LoginIcon } from '@mui/icons-material';
function Alexalogin(props) {
    const navigate = useNavigate();
    const [inputField, setInputField] = useState({
        user_email: "",
        otp: "",
        userPassword: "",
        confirmPassword:"",
        schoolCode: "",
        user_reg_num:""
        
    });

    const [isUserValid, SetisUserValid] = useState(true);
    const [codeForOauth, SetCodeForOauth] = useState("");
    const [loading, SetLoading] = useState(false);
    const [errorTxt, SeterrorTxt] = useState("");
    const [__customerLists, __setCustomerLists] = useState([]);
    const [searchSchool, SetSearchScool] = useState(false);
    const [filteredSchlLst, SetFilteredSchlLst] = useState([]);
    const [slctdSchl, SetSlctSchl] = useState("");
    const [slctSchlCode, SetSlctSchlCode] = useState("");
    const [isUserNew, setIsUserNew] = useState(true);
    const [userLoaded, setUserLoaded] = useState(false);
    const [acontId, setAcntId] = useState("");
    const [userId, setUserId] = useState("");
    const [__userRegisterNumber,__setUserRegisterNumber] = useState("");

    const [passwordError, setPasswordError] = useState("");

    const anim = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 1000 },
    });

    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        
        const { name,value} = e.target;
        if (name == "user_reg_num") {
            setUserLoaded(false);
            SeterrorTxt("");
            console.log(name, value.substring(14))
            let scholCode = value;
            if (value.length >= scholCode.length) {
                let roleNum = value.substring(14);
                SetSlctSchlCode(scholCode + roleNum)
            }
            
        }

        setInputField({
            ...inputField,
            [name]: value,
        });

        if(name === "userPassword" || name === "confirmPassword"){
            if(inputField.userPassword !== value && name === "confirmPassword"){
                setPasswordError("Passwords do not match.");
            }else if(name === "userPassword" && inputField.confirmPassword !=="" && inputField.confirmPassword !== value){
                setPasswordError("Passwords do not match.")
            }else{
                setPasswordError("");
            }
        }
    };


    const onSearchSchool = (e) => {
        setUserLoaded(false);
        SeterrorTxt("");
        SetSlctSchl(e.target.value)
        let key = e.target.value.trim().toLowerCase()
        SetSearchScool(true);
        console.log("key",key);
        console.log(__customerLists);
        const filtredLst = __customerLists.filter((dt) => {
            console.log("-------------", dt);
            //if (dt.customerDetails[0]) {
                return dt.customerName?.toLowerCase().includes(key.toLowerCase());
            //}
        })
        console.log(":ssssssssssssssssss", filtredLst);
        SetFilteredSchlLst(filtredLst)
    }

    useEffect( async() => {
        //Loading all shcools for search 
        await axios.get("/customers")
            .then(res => {
                console.log("All Schools list recieved:", res.data);
                __setCustomerLists(res.data)
            })
            .catch(err => {
                console.log("Error when tried to access schools list:", err);
            })
    }, [])

    // useEffect(() => {
    //     axios.get("/quiz/getAllQuizBatchUserDetails")
    //         .then(res => {
    //             console.log("already batch students", res.data);
    //             setAlreadyQuizUsers(res.data.quizBatchUsers)
    //             setAllQuizDetails(res.data.quizDetails)
    //         })
    //         .catch(err => {
    //             console.log("Error when tried to access schools list:", err);
    //         })
    // }, [])

    //Login button 
    const onSubmitBtn = async (e) => {
        e.preventDefault();
        SetLoading(true);

        let payload = {
            regNum: inputField.user_reg_num,
            otp: inputField.otp,
            userPassword: inputField.userPassword,
            confirmPassword: inputField.confirmPassword,
            id: acontId,
            userId: userId
        };
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_uri = params.get("redirect_uri");
        const state = params.get("state");
        const code = params.get("code");
        console.log(isUserNew);
        if (isUserNew) {
            console.log("Updating password:  ", payload, "===", userId);

            const _getResponseData = await axios.post("/customerUsers/updatePwd",payload);
            console.log("_getResponseData",_getResponseData);
            if(_getResponseData.data.status === "success"){
                linkiAlexa();
            }else{
                SeterrorTxt(_getResponseData.data.message);
            }
            //console.log(_getResponseData.data.status);
            /*axios.post('/customerUsers/updatePwd', payload).then((res) => {
                console.log("Not verified", res.data);
                
               if (res.data.isPasswordUpdated) {
                    linkiAlexa()
                }
                else {
                    SeterrorTxt(res.data.message)
                    console.log("Not verified", res.data);
                }
                SetLoading(false) 
            }) */
        }
        else {
            axios.post('/customerUsers/verifyPwd', payload).then((res) => {
                console.log(res.data);
                if (res.data.verified) {
                    linkiAlexa()
                }
                else {
                    SeterrorTxt(res.data.message)
                    console.log("Not verified");
                }
                SetLoading(false)
            })
        }
    };


    const linkiAlexa = (e) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_uri = params.get("redirect_uri");
        const state = params.get("state");
        const code = params.get("code");

        axios.post("/alexa/updateAccountLinkStatus").then((res) => {
            console.log(res.data.message);
        });

        console.log("linkiAlexa",codeForOauth);


     window.location.replace(
            `${redirect_uri}?state=${state}&code=${codeForOauth}`
        );
    }



    const onSchoolSelect = (e) => {
        setUserLoaded(false);
        SeterrorTxt("");
        // console.log("Selected school: ", filteredSchlLst[e][0].schoolName);
        console.log("Selected school: ", filteredSchlLst[e]);
        const _getCustomerName = filteredSchlLst[e]?.customerName ? filteredSchlLst[e]?.customerName : filteredSchlLst[e]?.schoolDetails[0]?.schoolName
        SetSearchScool(false)
        setAcntId(filteredSchlLst[e]._id)
        SetSlctSchl(_getCustomerName);
    }


    const onNextBtn = (e) => {
        e.preventDefault()
        let payload = {
            regNum: inputField.user_reg_num,
            schoolCode: slctdSchl,
            customerAccountId: acontId,
        };
        SeterrorTxt("")
        console.log("Verify user Payload", payload);
        axios.post("/alexa/alexaLoginVerifyUser", payload)
            .then((res) => {
                console.log(res.data);
                if (res.data.message != "") {
                    SeterrorTxt(res.data.message)
                    SetCodeForOauth(res.data.code)
                    setUserId(res.data.userId)
                }
                else {
                    setUserLoaded(true)
                    SetCodeForOauth(res.data.code)
                    setUserId(res.data.userId)
                    res.data.isUserAlreadyExist ? setIsUserNew(false) : setIsUserNew(true)
                }
            }).catch((err) => {
                console.log("--------", err);
            })
        console.log("dddddddddddddd", payload);
    }

    return (
        <div>
            <animated.div style={anim} id="REGISTER_FORM">
                {/* {loading ? <Loading /> : null} */}
                <Container style={{ maxWidth: "500px" }} >
                    <Row>
                        <Col xs={12} className={"mt-4"}>
                            <div style={{ textAlign: "center" }}>
                                <label style={{ fontFamily:basic.fontFamily, fontSize:basic.headingFontSize, fontWeight: "bold" }}> Please link your learning matters account (V3) </label>
                            </div>
                        </Col>
                        <Col xs={12} className={"mt-2"}>
                            <TextField required sx={textFieldAndShrinkStyle} size="medium" value={slctdSchl} name="schoolCode" id="outlined-basic" label="Customer Name" variant="outlined" style={{ width: "100%" }} 
                            onChange={onSearchSchool} onClick={onSearchSchool}
                            error={slctdSchl === ""}
                            helperText={
                                slctdSchl === ""
                                ? "Customer name is required."
                                 : "" }
                            /> 
                        </Col>
                        <Col xs={12}>
                            {
                                searchSchool ? <Box item xs={12} className="d-flex justify-content-center" sx={{fontFamily:basic.fontFamily}} style={{ position: "relative" }}>
                                    {
                                        filteredSchlLst.length >= 1 ? <Box className="search-list-overlay" sx={{fontFamily:basic.fontFamily}}>
                                            {
                                                filteredSchlLst.map((dt, index) => {
                                                    return <Box xs={12} key={index}>
                                                        <Button key={index} onClick={() => { onSchoolSelect(index); SetSearchScool(false); SetSlctSchlCode((dt.lmCode) + "_") }} sx={{fontFamily:basic.fontFamily}} style={{ textAlign: "start"}}>
                                                            <p style={{fontSize:basic.contentFontSize, marginBottom:"5px"}}> <b>{dt?.customerName} </b><span style={{ color: "#353535", textTransform: "initial" }}>{dt?.address}</span></p>
                                                        </Button>
                                                    </Box>
                                                })
                                            }
                                        </Box> : <Typography variant="h6" component="div" style={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily, color: "#000000", textAlign:"center", width:"100%", marginTop:"10px" }}>No customers Found</Typography>
                                    }
                                </Box> : null
                            }
                        </Col>
                        <Col xs={12}>
                            <div className="mt-3">
                                <TextField required  sx={textFieldAndShrinkStyle} size="medium" name="user_reg_num" id="outlined-basic1" label="User Reg. No." variant="outlined" style={{ width: "100%" }} 
                                value={inputField.user_reg_num}
                                autoComplete="off" onChange={onChangeTxt} 
                                error={inputField.user_reg_num === ""}
                            helperText={
                                inputField.user_reg_num === ""
                                ? "User register number is required."
                                 : "" }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col container direction="column" rowSpacing={2} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
                            {
                                userLoaded ? <Col item xs={12} className="mt-4">
                                    {
                                        isUserNew ? <Col container rowSpacing={2}>
                                            <Col item xs={12} className={"mt-4"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    size="medium" 
                                                    required
                                                    name="otp" 
                                                    id="outlined-basic2" type="pin" 
                                                    label="OTP" variant="outlined" 
                                                    style={{ width: "100%" }} 
                                                    autoComplete="off" 
                                                    onChange={onChangeTxt}
                                                 />
                                            </Col>
                                            <Col item xs={12} className={"mt-4"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    type={"password"} 
                                                    required
                                                    size="medium" 
                                                    name="userPassword" 
                                                    id="outlined-basic3" label="Create Password" variant="outlined" 
                                                    style={{ width: "100%" }} 
                                                    autoComplete="off" 
                                                    onChange={onChangeTxt} 
                                                    error={!!passwordError} 
                                                    
                                                />

                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    required
                                                    type={"password"} 
                                                    size="medium" 
                                                    name="confirmPassword" 
                                                    id="outlined-basic4" 
                                                    label="Confirm Password" 
                                                    variant="outlined" 
                                                    style={{ width: "100%" }} autoComplete="off" 
                                                    error={!!passwordError} 
                                                    helperText={passwordError}
                                                    onChange={onChangeTxt} 
                                                />
                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"} >
                                                <ButtonComponent
                                                    disabled=
                                                    {!(slctdSchl && slctdSchl.length > 0 
                                                        && inputField.user_reg_num && inputField.user_reg_num.length > 0
                                                        && inputField.otp && inputField.otp.length > 0
                                                        && inputField.userPassword && inputField.userPassword.length > 0
                                                        && inputField.confirmPassword && inputField.confirmPassword.length > 0
                                                        && !passwordError
                                                    )}
                                                    onClick={onSubmitBtn}
                                                    aria-label="LOGIN"
                                                    >   
                                                         <LoginIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                                        <div style={{ marginLeft: 5 }}>LOGIN</div>                                                       
                                                </ButtonComponent>
                                            </Col>
                                        </Col> : <Col container rowSpacing={2}>
                                            <Col item xs={12} className={"mt-4"} >
                                                <TextField sx={textFieldAndShrinkStyle} 
                                                size="medium" 
                                                required
                                                name="userPassword" 
                                                id="outlined-basic5" 
                                                type={"password"} 
                                                label="Password" 
                                                variant="outlined" 
                                                style={{ width: "100%" }} 
                                                autoComplete="off" onChange={onChangeTxt} />
                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                                <ButtonComponent                                                
                                                    disabled=
                                                    {!(slctdSchl && slctdSchl.length > 0 
                                                        && inputField.user_reg_num && inputField.user_reg_num.length > 0
                                                        && inputField.userPassword && inputField.userPassword.length > 0
                                                    )}
                                                    onClick={onSubmitBtn}
                                                    aria-label="LOGIN"
                                                    >   
                                                         <LoginIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                                        <div style={{ marginLeft: 5 }}>LOGIN</div>                                                       
                                                </ButtonComponent>
                                              
                                            </Col>
                                        </Col>
                                    }
                                </Col> : <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                    <ButtonComponent
                                        disabled={!(slctdSchl && slctdSchl.length > 0 && inputField.user_reg_num && inputField.user_reg_num.length > 0)}
                                        onClick={onNextBtn}
                                        aria-label="Next"
                                    >   
                                         <div style={{ marginLeft: 5 }}>Next</div>
                                         <NavigateNextIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                    </ButtonComponent>
                                </Col>
                            }

                            <Col item xs={12} sx={{ fontFamily:basic.fontFamily, fontSize:basic.contentFontSize}} style={isUserValid ? { textAlign: "center", display: "none" } : { textAlign: "center", display: "block" }}>
                                <div>
                                    {
                                        isUserValid ? <label style={{ fontWeight: "bold", color: "red" }}>{errorTxt} </label> :
                                            <label style={{ fontWeight: "bold", color: "red" }}>
                                                {errorTxt}
                                            </label>
                                    }
                                </div>
                            </Col>
                            <Col item xs={12} alignItems="center" justifyContent="center" style={{ textAlign: "center" }}>
                                <span style={{ fontSize: basic.contentFontSize, color: "red" }}>
                                    {errorTxt}
                                </span>
                            </Col>
                        </Col>

                    </Row>
                </Container>
            </animated.div>
        </div>
    );
}

export default Alexalogin;