import React, { useState } from "react";
import { Button, Container, Grid, TextField, Box, useMediaQuery } from "@mui/material";
import axios from "axios";
import LogoImage from '../../../src/assets/images/logo.png';
import bgImg from "./../../components/Assets/authoring-bg.jpg";
import PageHeading from "../Admin/page_heading";
import CustomButton from "../Admin/common/button_one";
import InputTextFieldComponent from "../Admin/common/input_text_field";
import { Save, Login } from '@mui/icons-material';
import { basic } from "../../themes/basic";
import messages from "../../themes/messages.json";
import LeftResetPageImage from '../../../src/assets/images/reset-password.png';

const ResetPassword = () => {

    const __isSmallScreen = useMediaQuery("(max-width:900px)");

    const [__pin, __setPin] = useState("");
    const [__newPassword, __setNewPassword] = useState("");
    const [__confirmPassword, __setConfirmPassword] = useState("");   
    const [__message, __setMessage] = useState("");
    const [__isSubmitDisabled, __setIsSubmitDisabled] = useState(false);
    const [__errorsMsg, __setErrorsMsg] = useState({});

    const __handlePinChange = (e) => {
        __setPin(e.target.value)       
    };
    const __handleNewPasswordChange = (e) => {
        __setNewPassword(e.target.value);
    };
    const __handleConfirmPasswordChange = (e) => {
        __setConfirmPassword(e.target.value);
    };

    const __handleSubmit = async (e) => {
        e.preventDefault();
        __setErrorsMsg({});
        __setMessage({});
        let __hasError = false;

        const _pinRegx = /^[0-9]{6}$/;
        const _pinValue = __pin.trim();
        const _passwordValue = __newPassword.trim();
        const _confirmPasswordValue = __confirmPassword.trim();

        if(_pinValue === ""){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                pin: messages.resetPassword.pinMsg
              }));
            __hasError = true;
            return;
        }else if (!_pinRegx.test(_pinValue)) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                pin: messages.resetPassword.pinMsgTwo
              }));
            __hasError = true;
            return;
        } 

        if(_passwordValue === ""){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                newPassword: messages.resetPassword.newPasswordMsg
              }));
            __hasError = true;
            return;
        }

        if(_confirmPasswordValue === ""){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                confirmPassword: messages.resetPassword.confirmPasswordMsg
              }));
            __hasError = true;
            return;
        }

        if (_passwordValue !== _confirmPasswordValue) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                confirmPassword: messages.resetPassword.messageOne
              }));
            __hasError = true;
            return;
        }

        if(!__hasError){
            const _search = window.location.search;
            const _params = new URLSearchParams(_search);
            const _userEmail = _params.get("userEmail");

            let _payload = {
                userEmail: _userEmail,
                pin: __pin,
                newPassword: __newPassword
            }

            const _platform = _params.get("platform");
            const _forgotPasswordForPlatform = _platform;        
            try {
                const _response =  await axios.post(`/${_forgotPasswordForPlatform}/resetPassword`, _payload);
                if(_response.data.status === "success"){
                    __setMessage({ type:"success", text: _response.data.message || messages.resetPassword.successMsg});
                    __setIsSubmitDisabled(true);
                }else{
                    __setMessage({ type:"error", text: _response.data.message || messages.resetPassword.errorMsgOne});
                }
            }catch(error){
                console.log("error",error);
                __setMessage({ type:"error", text: error.message || messages.resetPassword.errorMsgTwo });
            }
        }
    }

    return (
        <div style={{  padding:"20px 0px", minHeight: "100vh", background: `url(${bgImg})`, backgroundSize: "cover" }} >

            {/* Logo Section */}
            <Grid container style={{ margin:"auto", textAlign:"center", display:"block", marginTop:"10px"}}>
                <img src={LogoImage} alt="logoImg" style={{ width: "150px", marginRight: "0px" }}></img>
            </Grid>

            {/* Main Container */}
            <Grid 
            container
            sx={{ 
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: basic.colorWhite,
                width: "80%",
                height:"90%",
                maxWidth: "1000px",
                margin: "30px auto 0px auto",
                overflow: "hidden",
                padding:4,
            }}> 
                <Grid
                item
                xs={12}
                md={12}
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    padding: 0,
                    backgroundColor: '#fff',
                }}
                >
                    {/* Left Panel - Image */}
                    {!__isSmallScreen && (
                        <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            borderRight:"1px solid #ccc"
                        }}
                        >
                            <img
                            src={LeftResetPageImage}
                            alt="Reset Password"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "230px",
                                objectFit: "contain",
                            }}
                        />
                        </Grid>
                    )}
                    <Grid
                    item
                    xs={12}
                    md={__isSmallScreen ? 12 : 6} // Full width on small screens
                    //container
                    //alignItems="center"
                    //justifyContent="center"
                    style={{
                        padding: "35px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                        <Box
                        sx={{
                            width: '100%',
                            maxWidth: "100%",
                            padding: 0,
                            textAlign: 'center',
                        }}
                        >   
                            <PageHeading title="RESET PASSWORD" alignment="center" fontSize="22px" />

                            {/* PIN Field */}
                            <InputTextFieldComponent 
                                required
                                size="medium"
                                name="enter_pin"
                                label="PIN" 
                                value={__pin}
                                variant="outlined" 
                                style={{ width: "100%", margin:"30px 0px 20px 0px" }} 
                                onChange={__handlePinChange}
                                error={Boolean(__errorsMsg.pin)}
                                helperText={__errorsMsg.pin || ""}
                            />

                            {/* New Password Field */}
                            <InputTextFieldComponent 
                                required
                                size="medium"
                                name="enter_new_password"
                                label="New Password" 
                                value={__newPassword}
                                type="password"
                                variant="outlined" 
                                style={{ width: "100%", margin:"0px 0px 20px 0px" }} 
                                onChange={__handleNewPasswordChange}
                                error={Boolean(__errorsMsg.newPassword)}
                                helperText={__errorsMsg.newPassword || ""}
                            />

                            {/* Confirm Password Field */}
                            <InputTextFieldComponent 
                                required
                                size="medium"
                                name="confirm_new_password"
                                label="Confirm Password" 
                                value={__confirmPassword}
                                type="password"
                                variant="outlined" 
                                style={{ width: "100%", margin:"0px 0px 20px 0px" }} 
                                onChange={__handleConfirmPasswordChange}
                                error={Boolean(__errorsMsg.confirmPassword)}
                                helperText={__errorsMsg.confirmPassword || ""}
                            />

                            {/* SUBMIT Button */}
                            <CustomButton
                            onClick={__handleSubmit}
                            aria-label="SUBMIT"
                            title="SUBMIT"
                            disabled={__isSubmitDisabled}
                            >
                                <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                                <div style={{ marginLeft: 5 }}>SUBMIT</div>
                            </CustomButton>

                            <Grid item md={12} style={{ paddingTop:"0px"}} sx={{ textAlign: "center" }}>     
                                {__message.text && (
                                    <div 
                                        className="text-center p-3" 
                                        style={{
                                            marginTop: "10px",
                                            color: __message.type === "error" ? "red" : "green",
                                            fontFamily: basic.fontFamily,
                                            fontSize: basic.contentFontSize,
                                            paddingBottom:"0px !important"
                                        }}
                                    >
                                        {__message.text}
                                    </div>
                                )}
                                {__message.type === "success" && (
                                    <>
                                        <p style={{fontSize:"14px", marginTop:"5px"}}>
                                            Click <a href="/"> <b>here</b> </a> to login
                                        </p>
                                    </>
                                )}              
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
export default ResetPassword;