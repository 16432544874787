import React, { useEffect, useState } from 'react'
import { Modal, Box, Grid, Typography, Button, List, ListItem, CircularProgress } from "@mui/material";
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, 
  PersonAddAlt as AssignUserIcon,
  Close as CloseIcon, Save, Check } from '@mui/icons-material';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import CancelButton from '../common/button_cancel';
import CustomButton2 from "../common/button_two";
import SubscriptionGradeList from './subscription_grade_list';
import { red } from '@mui/material/colors';
import CloseButton from '../common/close_button';
import Header from '../common/heading_popup';
import axios from 'axios';

const UserModalComponent = ({
  isOpen,
  onClose,
  title,
  userDetails,
  setUserDetails,
  errors,
  roleOptions,
  grades,
  currentGrade,
  setCurrentGrade,
  handleAddGrade,
  handleRemoveGrade,
  handleSaveSubmit,
  initialData = {},
  editUserSubscription,
  mode,
  buttonEnabled = false,
  fetchingGroupSuggestions,
  handleSelectGroup,
  loadingGroup,
  setLoadingGroup,
  setGroupSuggestions,
  customerAccountId,
}) => {
  
    const [__groupSuggestions, __setGroupSuggestions] = useState([]);
    const [__loadingGroup,__setLoadingGroup] = useState(false);


    useEffect(() => {
      console.log("Mode",mode,loadingGroup);
      if (mode === 'edit') {
        // Preload for edit mode
        console.log("Edit Mode",mode);
      }
    }, [mode]);

  const __handleGroupChange = (e) => {
    const _inputValue = e.target.value;  
    setUserDetails({ ...userDetails, group: _inputValue });
    console.log(_inputValue);

    if (_inputValue.trim() === "") {
      __setGroupSuggestions([]);
      return;
    } else {      
      __fetchSuggestions(_inputValue);
    }

  };

  const __handleSelectGroup = (aSelectedGroup) => {
    setUserDetails({ ...userDetails, group: aSelectedGroup });    
    __setGroupSuggestions([]);
  }

  const __fetchSuggestions = async (aInputValue) => {
    if (!aInputValue) {
      __setGroupSuggestions([]);
      return;
    }
    __setLoadingGroup(true);
    try {
      const _response = await axios.get(`/customerUsers/check-group/${customerAccountId}`, {
        params: { group: aInputValue },
      });
      const _suggestions = _response.data.data || [];
      console.log(_suggestions);
      __setGroupSuggestions(_suggestions);
    } catch (error) {
      console.error("Error fetching group suggestions:", error);
    } finally {
      __setLoadingGroup(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} visible={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          width: "100vw",
          position: "fixed",
          top: 20,
          left: 0,
        }}
      >
        <Box
          sx={{
            width: 500,
            height: "auto",
            backgroundColor: basic.colorWhite,
            //margin: 'auto',
            padding: 0,
            position: 'relative',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            borderRadius: "5px",
            padding:"0px"
          }}
        >

          <Header title={title} />
          <CloseButton onClose={onClose} />
          

          <Grid container spacing={2} style={{ padding: "10px 20px 20px 20px", marginTop: 0 }}>
            {/* User Name */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="User Name"
                value={userDetails.userName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userName: e.target.value })
                }
                error={Boolean(errors.userName)}
                helperText={errors.userName || ""}
              />
            </Grid>

            {/* User Email */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="User Email"
                value={userDetails.userEmail}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userEmail: e.target.value })
                }
                error={Boolean(errors.userEmail)}
                helperText={errors.userEmail || ""}
              />
            </Grid>

            {/* User Role */}
            <Grid item md={12}>
              <DropdownComponent
                label="User Role"
                value={userDetails.userRole}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userRole: e.target.value })
                }
                options={roleOptions}
                error={Boolean(errors.userRole)}
                helperText={errors.userRole || ""}
              />
            </Grid>

            {/* User Grade */}
            <Grid item md={12}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <InputTextFieldComponent
                  required
                  label="User Grade"
                  value={currentGrade}
                  onChange={(e) => setCurrentGrade(e.target.value)}
                  error={Boolean(errors.grade)}
                  helperText={errors.grade || ""}
                  //labelShrink
                />
                <Button
                  disabled={!currentGrade.trim().length}
                  onClick={handleAddGrade}
                  variant="contained"
                  sx={{
                    minWidth:"40px",
                    mt: 0,
                    height:"37px",
                    backgroundColor:basic.logoGreyColor,
                    padding:"0px",
                    "&:hover": {
                      bgcolor: basic.greenColor,
                      color: basic.colorWhite,
                      iconcolor: basic.greenColor,
                    }
                  }}  
                >
                  <AddIcon fontSize='30' />
                </Button>
              </Box>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>                
                <SubscriptionGradeList
                  grades={grades}
                  editUserSubscription={editUserSubscription}
                  handleRemoveGrade={handleRemoveGrade}
                />                               
              </Box>
            </Grid>

            {/* Date of Birth */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="Date of Birth"
                type="date"
                value={userDetails.dob}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, dob: e.target.value })
                }
                error={Boolean(errors.dob)}
                helperText={errors.dob || ""}
                InputLabelProps={{ shrink: true }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </Grid>

            {/* User Group */}
            <Grid item md={12}>
              <InputTextFieldComponent
                label="Group"
                value={userDetails.group}
                /*onChange={(e) =>
                  setUserDetails({ ...userDetails, group: e.target.value })
                }*/
                onChange={__handleGroupChange}
                autoComplete="off" 
              />

              {__loadingGroup ? (
                <CircularProgress style={{ color: basic.greenColor }} size={24} sx={{ marginTop:"5px", marginLeft:"5px"}} />
              ) : (
                __groupSuggestions.length > 0 && (
                <List style={{ display:"block", backgroundColor:"#fff", paddingBottom:"0px"}}>
                  {__groupSuggestions.map((courseItem) => (
                    <ListItem
                      key={courseItem._id}
                      onClick={() => __handleSelectGroup(courseItem.name)}
                      style={{ color: basic.colorWhite, paddingBottom:"5px", cursor:"pointer", backgroundColor:basic.logoGreyColor, borderRadius:"5px", marginRight:"5px", display:"inline-block", width:"auto", marginBottom:"5px", paddingTop:"5px", fontSize:basic.contentFontSize}}
                      title='Select Course'
                      sx={{
                        "& li.MuiListItem-root:last-child": {
                          borderBottom: "0px",
                        },
                      }}  
                    >
                      {courseItem.name}
                    </ListItem>
                  ))}
                </List>
              ))}

            </Grid>

            {/* Mobile Number */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="Mobile Number"
                value={userDetails.phonenumber}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phonenumber: e.target.value })
                }
                error={Boolean(errors.phonenumber)}
                helperText={errors.phonenumber || ""}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item md={12} sx={{ textAlign: "right" }}>
            <CancelButton onClick={onClose} aButtonText="Cancel" />
            <CustomButton2 
              aria-label="Save"
              onClick={handleSaveSubmit}
              disabled={buttonEnabled}
              style={{
                marginRight: "5px",
              }}
            >
              <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
              <div style={{ marginLeft: 5 }}>Save</div>
            </CustomButton2>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModalComponent;
